import { RemixBrowser } from '@remix-run/react';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';

if (navigator.userAgent.indexOf('iPhone') === -1) {
	document
		?.querySelector('[name=viewport]')
		?.setAttribute(
			'content',
			'width=device-width, initial-scale=1, minimum-scale=1',
		);
}

if (
	window.SENTRY.DEBUG ||
	(window.MODE === 'production' && window.SENTRY.DSN)
) {
	import('./utils/monitoring.client.tsx').then(({ init }) => init());
}

startTransition(() => {
	hydrateRoot(document, <RemixBrowser />);
});
